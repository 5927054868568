export default {
  title: 'Integrations',
  redshift: {
    title: 'S3 bucket for Redshift imports',
    subtitle: {
      saas: "Import files to Amazon Redshift using your own S3 bucket instead of CARTO's",
      onprem: 'Gives you the ability to import files to Amazon Redshift through an S3 bucket'
    },
    importNotAvailable: 'S3 needs to be configured. Contact your admin organization.',
    importNotAvailableAdmin: 'S3 needs to be configured from Settings > Advanced > Integrations',
    contextMenu: {
      edit: 'View or edit integration'
    },
    delete: {
      message: 'Do you want to delete S3 bucket for Redshift?',
      success: 'S3 Bucket for Redshift Integration has been deleted.'
    },
    form: {
      title: 'Your S3 Bucket credentials',
      success: 'All set! You are able to import files from your S3 Bucket.',
      message: {
        saas: "To use your own S3 bucket when importing files into Amazon Redshift (instead of CARTO's default bucket) you need to set it up.",
        onprem:
          'To enable importing files into Amazon Redshift you need to configure an S3 Bucket. First, tell us your S3 Bucket name and region and CARTO will generate a policy that you will need to apply in S3. This policy allows CARTO to read from that bucket to import using your Redshift connections.'
      },
      generatePolicy: 'Generate policy',
      s3Policy: {
        title: 'S3 Policy',
        message: {
          saas: 'This policy grants reading permissions to CARTO.  Add this bucket policy to your S3 Bucket.',
          onprem: 'This policy grants reading permissions to CARTO.  Add this bucket policy to your S3 Bucket.'
        },
        disabled: 'Add your credentials and click on "Generate policy" to view the S3 Bucket policy.',
        help: {
          title: 'How to apply S3 policies',
          message: {
            saas: "To ensure you're using your own S3 bucket when importing local files to Amazon Redshift, please follow the instructions documented here.",
            onprem:
              'To ensure you can import Redshift from CARTO please follow the detailed instructions to securely setup your Redshift S3 bucket.'
          }
        },
        added: 'I have already added the S3 bucket policy.'
      },
      save: 'Validate and save'
    }
  },
  externalIdp: {
    title: 'OAuth connections to Snowflake',
    description: 'Allows users to create Snowflake connections via Snowflake/External OAuth',
    formDescription:
      "To avoid using usernames and passwords when connecting to Snowflake, you can set up an OAuth integration. This allows users to use their own Snowflake login or an external Identity Provider to connect CARTO and Snowflake. Learn more about it at <link>Snowflake's introduction to OAuth</link>",
    oAuthType: 'OAuth type',
    authorizationServer: 'Authorization server',
    configuration: 'Configuration',
    snowflakeAccountName: 'Snowflake Account Name',
    snowflakeAccountNamePlaceholder: 'E.g.: sxa81234.us-east-1',
    authURLSnowflakePlaceholder: 'E.g.: <snowflake_account_url>/oauth/authorize',
    authURLExternalPlaceholder: 'E.g.: https://authorization-server.com/oauth2/auth',
    accessTokensURL: 'Access Tokens URL',
    accessTokensURLSnowflakePlaceholder: 'E.g.: <snowflake_account_url>/oauth/token-request',
    accessTokensURLExternalPlaceholder: 'E.g.: https://authorization-server.com/oauth2/token',
    oAuthClientID: 'OAuth Client ID',
    oAuthClientIDPlaceholder: 'E.g.: A1B2C349aP...',
    oAuthClientSecret: 'OAuth Client Secret',
    oAuthClientSecretPlaceholder: 'E.g.: Z9Y8W7eY3U...',
    requirements: 'Requirements',
    requirementsDescription:
      "This integration assumes that this External OAuth authorization server has already been integrated in your Snowflake console. If you're unsure, we strongly recommend you follow the steps in our documentation.",
    securityIntegrationInfo: 'This code allows CARTO to connect via OAuth. Execute it in your Snowflake console.',
    securityIntegrationAbout: 'CARTO + Snowflake OAuth',
    securityIntegrationAboutDescription:
      "This integration assumes that you have already integrated Snowflake OAuth with CARTO using the provided SQL statement. If you're unsure, we strongly recommend you follow the steps in our documentation.",
    alreadyIntegrated: 'This external authorization server is already integrated in Snowflake',
    externalOAuth: 'External OAuth <strong>(Okta, Azure AD, or custom)</strong>',
    firstStep: 'Step 1 of 2',
    secondStep: 'Step 2 of 2',
    createOauth: 'Create an OAuth Security Integration in Snowflake',
    secondStepDescription:
      "Once you've created the integration in the Snowflake console using the code above, complete the form below.",
    form: {
      successNew: 'Integration successfully saved.',
      successEdit: 'Changes successfully saved.',
      error: 'An error occurred, please try again.'
    },
    edit: {
      title: 'Edit integration',
      subtitle: 'Are you sure you want to edit the integration',
      snowflakeProvider: 'OAuth connections for Snowflake',
      description:
        'All Snowflake connections using OAuth will immediately stop working. To repair the maps, workflows and applications where these connections are used, the owners will have to complete the OAuth flow again in each connection.',
      confirm: 'Yes, save changes'
    },
    delete: {
      title: 'Looking to remove this integration?',
      description:
        "This integration can't be deleted to prevent breaking permanently the connections using it. If you have any questions, please get in touch.",
      mail: 'Mail to Support Team'
    }
  },
  workloadsIdentityFederation: {
    tableDescription: 'Allows users to create BigQuery connections via Workload Identity Federation',
    description:
      'To avoid managing and uploading service accounts when connecting to BigQuery, you can set up a Workload Identity Federation integration. This allows your BigQuery administrators to grant grant IAM roles, service accounts or direct access to the data CARTO identities, directly in GCP. Learn more about <link>Workload Identity Federation in GCP</link>',
    requirements:
      "This integration assumes that this Workload Identity Federation server has already been integrated in your BigQuery console. If you're unsure, we strongly recommend you follow the steps in our documentation.",
    step1: {
      title: 'Create a Workload Identity Pool for CARTO in Google Cloud',
      description:
        'This pool will let you manage CARTO user identities directly from GCP. Follow this guide on <link>creating a Workload Identity Pool in GCP.</link>'
    },
    step2: {
      title: 'Configuration',
      description:
        "Once you've created the Workload Identity Pool in GCP using the details above, let us know the Default Audience URL below."
    },
    checkbox: 'My team is ready to grant BigQuery permissions to CARTO identities from this Workload Identity Pool',
    delete: {
      message:
        'If you continue you will delete <strong>Workload Identity Federation</strong> for BigQuery integration. All BigQuery connections using Workload identity Federation may stop working if permissions are not reassigned.'
    },
    success: 'Integration successfully added. Create a BigQuery connection to validate it.',
    edit: {
      message:
        'Are you sure you want to edit the integration <black>Workload Identity Federation for BigQuery</black>?',
      info: "All BigQuery connections using Workload identity Federation may stop working if permissions for the new pool do not match the old one. To repair the maps, workflows and applications you'll need to assign the right permissions in the new pool."
    }
  },
  postgresExport: {
    title: 'S3 bucket for PostgreSQL exports',
    subtitle:
      'Enable S3 bucket integration to seamlessly export data from RDS for PostgreSQL directly through CARTO Builder',
    form: {
      header: `Configuration`,
      helpTitle: `Requirements`,
      helpText: `This integration assumes that you have already integrated the AWS S3 extension into your PostgreSQL setup. If you're uncertain about this process, we strongly recommend following the instructions provided in our documentation.`
    },
    delete: {
      message:
        'If you continue you will delete <strong>RDS for PostgreSQL S3 Bucket</strong> integration. This change will affect the ability to export data from RDS for PostgreSQL directly through CARTO Builder.'
    }
  },
  s3bucket: {
    name: 'Bucket name',
    namePlaceholder: 'Type your bucket name',
    region: 'Region',
    regionPlaceholder: 'us-east-1',
    accessKeyId: 'Access key id',
    accessKeyIdPlaceholder: 'Type your bucket key id',
    secretAccessKey: 'Access key secret',
    secretAccessKeyPlaceholder: 'Type your bucket key secret',
    error: 'CARTO could not validate your S3 Bucket. Please try again.'
  },
  providers: {
    postgres: 'PostgreSQL',
    redshift: 'Amazon Redshift'
  },
  common: {
    delete: {
      title: 'Delete integration',
      yesDeleteIt: 'Yes, delete it'
    },
    validateAndSave: 'Validate and save',
    createSuccess: 'Integration successfully added.',
    updateSuccess: 'Changes successfully saved.',
    deleteSuccess: 'Integration successfully deleted.',
    deleteFailure: 'Failed to delete integration.',
    failedLoadBucket: 'Error loading bucket info. Please try again.',
    passwordPlaceholder: '••••••••••••••'
  }
}
